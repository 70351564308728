import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Card from "components/card";
import { createCustomer } from "interfaces/customer";
import InputField from "components/fields/InputField";
import {
    isFormErrored,
    isValidEmail,
    isValidPhoneNumber,
} from "utils/verification";
import GoBack from "components/actions/GoBack";

const ImportUberOrders = () => {
    const navigate = useNavigate();
    const [table, setTable] = useState([]);
    const [limit, setLimit] = useState(5);

    const euroStringToNumber = (euroString: string) => {
        return Number(
            euroString.replace("€", "").replace(" ", "").replace(",", "."),
        );
    };

    const handleInputChange = (e: any) => {
        let data = e.target.value;

        if (!data.includes("Versement estimé") && !data.includes("Avis"))
            return;

        data = data.split("Versement estimé")[2].split("Avis")[0];
        const rows = data.split("\n").filter((row: string) => row !== "");
        const chunk = 9;
        const table = [];
        for (let i = 0; i < rows.length; i += chunk) {
            table.push({
                order: rows[i],
                date: rows[i + 1],
                customerName: rows[i + 2],
                status: rows[i + 3],
                total: euroStringToNumber(rows[i + 4]),
                serviceFees: rows[i + 5],
                customerRefunt: rows[i + 6],
                orderFee: euroStringToNumber(rows[i + 7]),
                pointsToAdd: Math.round(
                    euroStringToNumber(rows[i + 4]) +
                        euroStringToNumber(rows[i + 7]),
                ),
                net: rows[i + 8],
            });
        }
        setTable(table);
    };

    useEffect(() => {
        if (localStorage.getItem("token") === null) {
            navigate("/login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <GoBack text="Mes clients" />
            <div className="mt-2 grid  grid-cols-12 border rounded-lg">
                <div className={`col-span-12`}>
                    <Card extra={"w-full !p-5"}>
                        {/* Header */}
                        <div className="w-full px-2">
                            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                                Import uberEats
                            </h4>
                        </div>
                        {/* inputs */}
                        <div className="mt-5 grid gap-3 grid-cols-12">
                            <p className="col-span-12 text-base">
                                Contenu page de commande
                            </p>
                            <div className="col-span-12 lg:col-span-12">
                                <textarea
                                    id="textInput"
                                    className="w-full h-[150px] px-4 py-2 border rounded-xl focus:outline-none focus:ring focus:border-blue-300 transition-colors duration-300"
                                    placeholder="Coller le contenu de la page de commande ici"
                                    onChange={handleInputChange}
                                ></textarea>
                            </div>
                        </div>
                        {/* Table */}
                        <div className="mt-5">
                            <table className="w-full">
                                <thead>
                                    <tr>
                                        <th className="mt-[20px] cursor-pointer border-b border-gray-200 pl-7 pt-4 pb-2 text-sm text-start dark:border-white/10">
                                            Numéro de commande
                                        </th>
                                        <th className="mt-[20px] cursor-pointer border-b border-gray-200 pl-7 pt-4 pb-2 text-sm text-start dark:border-white/10">
                                            Client
                                        </th>
                                        <th className="mt-[20px] cursor-pointer border-b border-gray-200 pl-7 pt-4 pb-2 text-sm text-center dark:border-white/10">
                                            Montant de la commande
                                        </th>
                                        <th className="mt-[20px] cursor-pointer border-b border-gray-200 pl-7 pt-4 pb-2 text-sm text-start dark:border-white/10">
                                            Réduction divers
                                        </th>
                                        <th className="mt-[20px] cursor-pointer border-b border-gray-200 pl-7 pt-4 pb-2 text-sm text-start dark:border-white/10">
                                            Points de fidélité
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {table.slice(0, limit).map((row, index) => (
                                        <tr key={index} className="mt-2">
                                            <td className="text-start">
                                                {row.order}
                                            </td>
                                            <td>{row.customerName}</td>
                                            <td className="text-center">
                                                {row.total}
                                            </td>
                                            <td>
                                                {row.orderFee === 0
                                                    ? "-"
                                                    : row.orderFee}
                                            </td>
                                            <td className="text-green-500 font-semibold">
                                                + {row.pointsToAdd} points
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {/* See more */}
                            <div className="mt-3 w-full">
                                <div className="flex justify-center">
                                    <button
                                        className="text-primary"
                                        onClick={() =>
                                            setLimit((prev) =>
                                                prev + 5 > table.length
                                                    ? table.length
                                                    : prev + 5,
                                            )
                                        }
                                    >
                                        Voir plus
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="flex mx-auto">
                            <button
                                className={`p-10 linear mt-10 w-full rounded-xl bg-primary text-white font-bold py-3 text-base transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                            >
                                Synchoniser les points de fidélité
                            </button>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default ImportUberOrders;
