import Pagination from "components/pagination/Pagination";
import gmailImage from "../../assets/img/gmail.png";
import autoImage from "../../assets/img/auto.png";
import phoneImage from "../../assets/img/phone.png";
import clientImage from "../../assets/img/client.png";
import targetImage from "../../assets/img/target.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Reengage = () => {
    const [page, setPage] = useState(1);
    const items: any = [];
    const nav = useNavigate();

    const ToolCard = (props: {
        path: string;
        icon: any;
        title: string;
        description: string;
        available?: boolean;
    }) => {
        const { path, icon, title, description, available = true } = props;

        return (
            <div
                className="relative border rounded-xl p-6 cursor-pointer hover:border-2 hover:bg-gray-50"
                onClick={() => nav(path)}
            >
                {!available && (
                    <div className="absolute right-1 top-1">
                        <p className="m-4 p-2 text-xs font-bold bg-gray-200 rounded-lg">
                            PROCHAINEMENT
                        </p>
                    </div>
                )}
                <div className="absolute right-1 top-1"></div>
                <img src={icon} alt="Data" className="w-[40px]" />
                <div>
                    <h1 className="mt-4 font-bold">{title}</h1>
                    <p className="text-sm mt-1">{description}</p>
                </div>
            </div>
        );
    };

    return (
        <div className="">
            <div className="mt-8 md:mt-3 grid grid-cols-1 md:grid-cols-2 gap-10">
                <div>
                    <h1 className="mt-2 text-xl md:mt-0 font-bold">
                        Campagnes
                    </h1>
                    <p className="mt-2 text-gray-600 text-sm">
                        Nos outils marketing vous permettent de créer des
                        campagnes efficaces
                    </p>
                    <div className="mt-4 w-[100%] h-[1px] bg-gray-200 rounded-full"></div>
                    <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <ToolCard
                            path="/email-model"
                            icon={gmailImage}
                            title="Campagnes email"
                            description="Utilisez l'éditeur et nos templates pour créer des campagnes email efficaces."
                        />
                        <ToolCard
                            path="/email-model"
                            icon={phoneImage}
                            title="Campagnes SMS"
                            description="Assurez vous d'être lu en envoyant des SMS promotionnels à vos clients."
                        />
                    </div>
                </div>
                <div>
                    <h1 className="text-xl md:mt-0 font-bold">
                        Réengager vos clients
                    </h1>
                    <p className="mt-2 text-gray-600 text-sm">
                        Faites revenir vos clients grâce à nos outils
                    </p>
                    <div className="mt-4 w-[100%] h-[1px] bg-gray-200 rounded-full"></div>
                    <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <ToolCard
                            path="/auto-marketing"
                            icon={autoImage}
                            title="Automatisations"
                            description="Déclenchez des campagnes automatiques pour réengager vos clients."
                        />
                        <ToolCard
                            path="/customers-lists"
                            icon={targetImage}
                            title="Ciblage publicitaire"
                            description="Envoyez le bon message au bon moment grâce à notre outil de ciblage publicitaire."
                        />
                    </div>
                </div>
                <Pagination
                    extra="mt-3"
                    text={false}
                    maxItems={items.length}
                    itemsPerPage={[8]}
                    page={page}
                    onChange={(page: number, limit: number) => setPage(page)}
                />
            </div>
        </div>
    );
};

export default Reengage;
