import GoBack from "components/actions/GoBack";
import {
    getAllContent,
    getContentDescription,
    updatePost,
    updatePostCaption,
} from "interfaces/content";
import { useEffect, useState } from "react";
import { FaInstagram } from "react-icons/fa";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { MdArrowBack, MdArrowLeft, MdArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { dateToFrench } from "utils/date";

function GenerateDescription() {
    const [content, setContent] = useState<any>([]);
    const [index, setIndex] = useState<number>(0);
    const nav = useNavigate();

    const initContent = async () => {
        const res = await getAllContent();
        if (res.error) return;
        const contentWithoutDescription = res.filter(
            (c: any) => c.caption === "",
        );
        setContent(contentWithoutDescription);
    };

    const generateDescription = async (id: string) => {
        const res = await getContentDescription(id);
        if (res.error) return;
        setContent((prev: any) =>
            prev.map((c: any) => {
                if (c._id === id) {
                    c.caption = res.caption.replaceAll(`"`, "");
                }
                return c;
            }),
        );
    };

    const updateCaption = async (id: string) => {
        const res = await updatePostCaption(id, {
            caption: content[index]?.caption,
        });
        if (res.error) return;
        if (index < content.length - 1) setIndex(index + 1);
        else nav("/calendar");
    };

    useEffect(() => {
        initContent();
    }, []);

    const difBetweenDates = (date1: Date, date2: Date) => {
        const diff = date1.getTime() - date2.getTime();

        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        return days;
    };

    return (
        <div>
            <div className="relative mt-1 grid grid-cols-1 md:grid-cols-2">
                <div className="absolute top-2 left-0">
                    <GoBack />
                </div>
                <div className="flex col-span-2 mx-auto items-center gap-2 justify-between">
                    <MdArrowLeft
                        size={30}
                        className="hover:cursor-pointer"
                        onClick={() => {
                            if (index > 0) setIndex(index - 1);
                        }}
                    />
                    <h1 className="text-2xl font-bold">
                        Publication {index + 1}/{content?.length}
                    </h1>
                    <MdArrowRight
                        size={30}
                        className="hover:cursor-pointer"
                        onClick={() => {
                            if (index < content.length - 1) setIndex(index + 1);
                        }}
                    />
                </div>
                {/* Skeletton loader */}
                {content.length === 0 && (
                    <div className="mt-3 relative col-span-12 bg-white shadow-lg rounded-lg p-4 border animate-pulse">
                        <div className="flex gap-2 my-auto">
                            <FaInstagram
                                size={20}
                                className="flex my-auto text-[#515151e9]"
                            />
                            <div className="w-[200px] h-[15px] rounded-lg bg-gray-500"></div>
                        </div>
                        <div className="absolute top-4 right-3 text-sm">
                            <div className="w-[240px] h-[10px] rounded-lg bg-gray-100"></div>
                        </div>
                        <div className="flex gap-2">
                            <div className="mt-2 w-[20%] h-[200px] bg-gray-100 rounded-lg border-2 border-black-200"></div>
                            <div className="mt-1 ml-5">
                                <div className="mt-3 w-[140px] h-[10px] rounded-lg bg-gray-100"></div>
                                <div className="mt-3 w-[200px] h-[10px] rounded-lg bg-gray-100"></div>
                                <div className="mt-3 w-[200px] h-[10px] rounded-lg bg-gray-100"></div>
                                <div className="mt-3 w-[160px] h-[10px] rounded-lg bg-gray-100"></div>
                            </div>
                        </div>
                    </div>
                )}
                {/* Display content */}
                {content.length > 0 && (
                    <div className="mt-3 relative col-span-12 bg-white shadow-lg rounded-lg p-4 border">
                        <div className="flex gap-2 my-auto">
                            <FaInstagram
                                size={20}
                                className="flex my-auto text-[#515151e9]"
                            />
                            <h1 className="my-auto text-base font-bold">
                                {difBetweenDates(
                                    new Date(content[index]?.start),
                                    new Date(),
                                ) > 0
                                    ? `Publication dans ${difBetweenDates(
                                          new Date(content[index]?.start),
                                          new Date(),
                                      )} jours`
                                    : "Publication imminente"}
                            </h1>
                            <p className="absolute top-4 right-3 text-xs md:text-sm">
                                {dateToFrench(content[index]?.start)} à{" "}
                                {
                                    content[index]?.start
                                        .split("T")[1]
                                        .split(":")[0]
                                }
                                H
                            </p>
                        </div>
                        <div className="flex gap-2">
                            <img
                                src={content[index]?.imageUrl}
                                alt={content[index]?.title}
                                className="mt-2 w-[20%] rounded-lg border-2 border-black-200"
                            />
                            <div className="ml-10 w-[400px]">
                                <label className="block text-gray-700 text-sm font-medium mb-2">
                                    Description de la publication
                                </label>
                                <textarea
                                    value={content[index]?.caption}
                                    onChange={(e) => {}}
                                    className="block w-full px-4 py-2 border border-gray-300 rounded-xl"
                                    rows={5}
                                    placeholder="Écrivez quelque chose..."
                                />
                            </div>
                            <div className="mt-2 ml-10 w-[400px]">
                                <p className="mt-1 text-sm font-bold">
                                    💡 Conseils
                                </p>
                                <ul className="mt-1 list-disc list-inside text-sm">
                                    <li>
                                        #️⃣ Utilisez des hashtags augmenter votre
                                        visibilité
                                    </li>
                                    <li>
                                        ✍️ Écrivez une description claire et
                                        concise
                                    </li>
                                    <li>👋 Ajoutez des emojis</li>
                                </ul>
                                <button
                                    className="flex mt-4 gap-2 text-xs bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl"
                                    onClick={() =>
                                        generateDescription(content[index]?._id)
                                    }
                                >
                                    Générer une description avec l'IA
                                    <FaWandMagicSparkles />
                                </button>
                            </div>
                        </div>
                        <p className="text-sm">{content[index]?.description}</p>
                        <div className="flex justify-end">
                            <button
                                className="flex text-md bg-primary hover:bg-blue-700 text-white py-2 px-4 rounded-xl"
                                onClick={() =>
                                    updateCaption(content[index]?._id)
                                }
                            >
                                Sauvegarder
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default GenerateDescription;
