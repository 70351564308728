import ReactCrop from "react-image-crop";
import GoBack from "components/actions/GoBack";
import { createPost, uploadMedia } from "interfaces/content";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import "react-image-crop/dist/ReactCrop.css";

const aspects = [
    {
        label: "carré (1:1)",
        value: 1 / 1,
    },
    {
        label: "portrait (4:5)",
        value: 4 / 5,
    },
];

function PostScheduler() {
    const nav = useNavigate();
    const [loading, setLoading] = useState(false);
    const [platform, setPlatform] = useState("instagram");
    const [media, setMedia] = useState<any>(null);
    const [file, setFile] = useState(null);
    const [text, setText] = useState("");
    const [error, setError] = useState("");
    const [schedule, setSchedule] = useState({ date: "", time: "" });
    const [crop, setCrop] = useState<any>({ aspect: 1 });
    const [aspect, setAspect] = useState(1);
    const imageRef = useRef(null);
    const canvasRef = useRef(null);

    const editAspect = (aspect: any) => {
        setAspect(aspect);
        if (aspect === 1) {
            setCrop({
                unit: "px",
                x: 50,
                y: 50,
                width: 200,
                height: 200,
                aspect: 1,
            });
        } else {
            setCrop({
                unit: "px",
                x: 50,
                y: 50,
                width: 200,
                height: 250,
                aspect: 4 / 5,
            });
        }
    };

    const handleMediaUpload = (e: any) => {
        const file = e.target.files[0];

        if (file) {
            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = () => {
                setMedia(URL.createObjectURL(file));
                setFile(file);
                setError("");
            };
        }
    };

    const getCroppedImage = useCallback((imageSrc: any, crop: any) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = imageSrc;
            image.crossOrigin = "anonymous";

            image.onload = () => {
                const canvas = document.createElement("canvas");
                const { width, height } =
                    imageRef.current.getBoundingClientRect();
                const scaleX = image.width / width;
                const scaleY = image.height / height;

                canvas.width = crop.width * scaleX;
                canvas.height = crop.height * scaleY;
                const ctx = canvas.getContext("2d");

                if (!ctx) {
                    reject(new Error("Could not get canvas context"));
                    return;
                }

                ctx.drawImage(
                    image,
                    crop.x * scaleX,
                    crop.y * scaleY,
                    crop.width * scaleX,
                    crop.height * scaleY,
                    0,
                    0,
                    crop.width * scaleX,
                    crop.height * scaleY,
                );

                canvas.toBlob((blob) => {
                    if (blob) {
                        const croppedFile = new File(
                            [blob],
                            "cropped_image.jpg",
                            {
                                type: "image/jpeg",
                            },
                        );
                        resolve(croppedFile);
                    } else {
                        reject(new Error("Image cropping failed"));
                    }
                }, "image/jpeg");
            };

            image.onerror = (err) => {
                reject(new Error("Failed to load image"));
            };
        });
    }, []);

    const handlePlatformChange = (e: any) => {
        setPlatform(e.target.value);
    };

    const handlePostCreation = async () => {
        if (!file) {
            setError("Veuillez sélectionner un média.");
            return;
        }
        setLoading(true);

        // Get the cropped image blob
        const croppedFile: any = await getCroppedImage(media, crop);
        if (!croppedFile) {
            setLoading(false);
            setError("Erreur lors du recadrage de l'image.");
            return;
        }

        // Create FormData with cropped image
        const formData = new FormData();
        formData.append("file", croppedFile, "cropped-image.jpg");

        // Upload media
        const uploadedMedia = await uploadMedia(formData);
        if (uploadedMedia) {
            const post = await createPost({
                platform: platform,
                images: [uploadedMedia.data.key],
                caption: text,
                mediaType: "image",
                isVerified: false,
                scheduledAt: new Date(
                    `${schedule.date}T${schedule.time}:00`,
                ).toISOString(),
            });
            if (post) {
                setLoading(false);
                nav("/calendar");
            }
        }
    };

    useEffect(() => {
        if (imageRef) {
            setCrop({
                unit: "px",
                x: 50,
                y: 50,
                width: 200,
                height: 200,
                aspect: 1,
            });
        }
    }, [imageRef]);

    useEffect(() => {
        const today = new Date();
        setSchedule({
            date: today.toISOString().split("T")[0],
            time: today.toLocaleTimeString("fr-FR", {
                hour: "2-digit",
                minute: "2-digit",
            }),
        });
    }, []);

    return (
        <div>
            <GoBack />
            <div className="mt-2 grid grid-cols-2 md:grid-cols-2 gap-4">
                <div className="col-span-1 bg-white shadow-lg rounded-lg p-4 border">
                    <h1 className="text-base font-bold">
                        Créer une publication
                    </h1>
                    <div className="mt-2 mb-4">
                        <label className="block text-gray-700 text-sm font-medium mb-2">
                            Plateforme
                        </label>
                        <select
                            value={platform}
                            onChange={handlePlatformChange}
                            className="block w-full px-4 py-2 border border-gray-300 rounded-xl"
                        >
                            <option value="both">Facebook + Instagram</option>
                            <option value="instagram">Instagram</option>
                            <option value="facebook">Facebook</option>
                        </select>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-medium mb-2">
                            Télécharger un Média
                        </label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleMediaUpload}
                            className="block w-full text-sm text-fidiz-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-primary/90 rounded-xl hover:file:bg-primary/80 file:cursor-pointer"
                        />
                        <p className="text-xs text-red-500 mt-2">{error}</p>
                    </div>
                    <canvas ref={canvasRef} style={{ display: "none" }} />

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-medium mb-2">
                            Écrire une Légende
                        </label>
                        <textarea
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            className="block w-full px-4 py-2 border border-gray-300 rounded-xl"
                            rows={4}
                            placeholder="Écrivez quelque chose..."
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-medium mb-2">
                            Planifier (facultatif)
                        </label>
                        <div className="flex space-x-4">
                            <input
                                type="date"
                                value={schedule.date}
                                onChange={(e) =>
                                    setSchedule({
                                        ...schedule,
                                        date: e.target.value,
                                    })
                                }
                                className="block w-1/2 px-4 py-2 border border-gray-300 rounded-xl"
                            />
                            <input
                                type="time"
                                value={schedule.time}
                                onChange={(e) =>
                                    setSchedule({
                                        ...schedule,
                                        time: e.target.value,
                                    })
                                }
                                className="block w-1/2 px-4 py-2 border border-gray-300 rounded-md"
                            />
                        </div>
                    </div>
                    {loading ? (
                        <div className="mt-4 text-center">
                            <p className="text-lg text-gray-500">
                                Publication en cours...
                            </p>
                        </div>
                    ) : (
                        <button
                            className="w-full p-5 linear rounded-xl bg-primary text-white font-bold py-3 text-xs transition duration-200 hover:opacity-70"
                            onClick={handlePostCreation}
                        >
                            Planifier le Post
                        </button>
                    )}
                </div>

                <div className="col-span-1 bg-white shadow-lg rounded-lg p-4 border">
                    <h1 className="text-base font-bold mb-4">
                        Prévisualisation
                    </h1>
                    <div className="mx-auto border-2 border-gray-200 rounded-lg p-4">
                        <div className="mb-4">
                            {media ? (
                                <ReactCrop
                                    crop={crop}
                                    onChange={(newCrop: any) =>
                                        setCrop(newCrop)
                                    }
                                    aspect={aspect}
                                >
                                    <img
                                        ref={imageRef}
                                        src={media}
                                        alt="crop preview"
                                    />
                                </ReactCrop>
                            ) : (
                                <div className="w-full h-64 bg-gray-200 rounded-lg flex items-center justify-center text-gray-500">
                                    Aucun média sélectionné
                                </div>
                            )}
                        </div>
                        {aspects.map((asp) => (
                            <button
                                key={asp.label}
                                onClick={() => editAspect(asp.value)}
                                className={`${
                                    aspect === asp.value
                                        ? "bg-primary text-white"
                                        : "bg-gray-200 text-gray-500"
                                } px-4 py-2 rounded-lg mr-2`}
                            >
                                {asp.label}
                            </button>
                        ))}
                        <div>
                            <p className="text-sm font-medium text-gray-500">
                                {platform === "facebook"
                                    ? "Post Facebook"
                                    : "Post Instagram"}
                            </p>
                            <p className="mt-2 text-base text-gray-800">
                                {text || "Votre texte apparaîtra ici"}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PostScheduler;
