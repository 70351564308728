import Pagination from "components/pagination/Pagination";
import tabletImage from "../../assets/img/tablet.png";
import clientImage from "../../assets/img/client.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getKpis } from "interfaces/customer";
import rouletteImage from "../../assets/img/wheel.png";
import FidelityDashboard from "views/performance/components/FidelityDashboard";

const Retain = () => {
    const [page, setPage] = useState(1);
    const [kpis, setKpis] = useState<any>(null);
    const items: any = [];
    const nav = useNavigate();

    const initKpis = async () => {
        const res = await getKpis(1, 1);
        if (res) setKpis(res);
        console.log(res);
    };

    useEffect(() => {
        initKpis();
    }, []);

    const ToolCard = (props: {
        path: string;
        icon: any;
        title: string;
        description: string;
        available?: boolean;
    }) => {
        const { path, icon, title, description, available = true } = props;

        return (
            <div
                className="relative border rounded-xl p-6 cursor-pointer hover:border-2 hover:bg-gray-50"
                onClick={() => nav(path)}
            >
                {!available && (
                    <div className="absolute right-1 top-1">
                        <p className="m-4 p-2 text-xs font-bold bg-gray-200 rounded-lg">
                            PROCHAINEMENT
                        </p>
                    </div>
                )}
                <div className="absolute right-1 top-1"></div>
                <img src={icon} alt="Data" className="w-[40px]" />
                <div>
                    <h1 className="mt-4 font-bold">{title}</h1>
                    <p className="text-sm mt-1">{description}</p>
                </div>
            </div>
        );
    };

    return (
        <div className="">
            <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-10">
                <div>
                    <h1 className="text-xl md:mt-0 font-bold">
                        Outils de fidélité
                    </h1>
                    <p className="mt-2 text-gray-600 text-sm">
                        Proposez une expérience unique à vos clients
                    </p>
                    <div className="mt-4 w-[100%] h-[1px] bg-gray-200 rounded-full"></div>
                    <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <ToolCard
                            path="/tablet-onboarding"
                            icon={tabletImage}
                            title="Tablette de fidélité"
                            description=" Fidélisez vos clients et collectez leurs coordonnées pour des campagnes marketing efficaces."
                        />
                        <ToolCard
                            path="/wheel-manager"
                            icon={rouletteImage}
                            title="Roue des cadeaux"
                            description="Captez des emails et SMS en permettant à vos clients de tenter de gagner un cadeau."
                        />
                    </div>
                </div>
                <div>
                    <h1 className="mt-2 text-xl md:mt-0 font-bold">
                        Relation client
                    </h1>
                    <p className="mt-2 text-gray-600 text-sm">
                        Restez proche de vos clients pour les fidéliser
                    </p>
                    <div className="mt-4 w-[100%] h-[1px] bg-gray-200 rounded-full"></div>
                    <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <ToolCard
                            path="/my-customers"
                            icon={clientImage}
                            title="CRM clients"
                            description="Visualisez tout vos clients et boostez leur engagement grâce à notre CRM."
                        />
                    </div>
                </div>
                <Pagination
                    extra="mt-3"
                    text={false}
                    maxItems={items.length}
                    itemsPerPage={[8]}
                    page={page}
                    onChange={(page: number, limit: number) => setPage(page)}
                />
            </div>
            <FidelityDashboard />
        </div>
    );
};

export default Retain;
