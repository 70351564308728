import {
    MdAutoGraph,
    MdCached,
    MdCampaign,
    MdHome,
    MdOutlinePersonPin,
    MdOutlineShoppingBag,
    MdOutlineSms,
    MdPallet,
    MdSupervisedUserCircle,
} from "react-icons/md";

// Admin Imports
import DashboardsDefault from "views/dashboards/default";
import DashboardsRTLDefault from "views/dashboards/rtl";
import DashboardsCarInterface from "views/dashboards/carInterface";
import DashboardsSmartHome from "views/dashboards/smartHome";

import AccountSettings from "views/admin/main/account/settings";

import SignInDefault from "views/auth/signIn/SignInDefault";
import SignUpDefault from "views/auth/signUp/SignUpDefault";
import CRM from "views/crm/list-customers";
import EditCustomer from "views/crm/editCustomer";
import EditInformation from "views/crm/editCustomer/editInformation";
import CreateCustomer from "views/crm/createCustomer";
import ImportCustomer from "views/crm/importCustomer";
import InsertLead from "views/admin/insert-lead";
import Marketing from "views/marketing";
import Home from "views/home/Home";
import PrintQr from "views/print-qr";
import Subscribe from "views/subscribe";
import CreateEmailModel from "views/email-model/create-email-model";
import EditEmailModel from "views/email-model/edit-email-model";
import ListEmainModel from "views/email-model/list-email-model";
import CustomersLists from "views/customers-lists/customers-lists";
import EditCustomersLists from "views/customers-lists/edit-customers-lists";
import CreateCustomersLists from "views/customers-lists/create-customers-lists";
import CreateCampaign from "views/marketing/create-campaign";
import ForgotPassword from "views/auth/forgotPassword/ForgotPasswordDefault";
import ResetPassword from "views/auth/forgotPassword/ResetPassword";
import Tokens from "views/tokens";
import CreateTemplate from "views/email-model/create-template";
import CreateSMSModel from "views/email-model/create-sms-model";
import EditSmsModel from "views/email-model/edit-sms-model";
import Ads from "views/ads/Ads";
import { IoCalendarOutline, IoSpeedometer } from "react-icons/io5";
import Tools from "views/tools/Tools";
import Ugc from "views/UGC/Ugc";
import Performance from "views/performance";
import WheelManager from "views/wheel-manager";
import Scan from "views/stock/scanner";
import LeadGen from "views/auth/AskADemo/LeadGen";
import PageManager from "views/page-manager";
import InConnect from "views/admin/in-connect";
import AutoMarketing from "views/auto-marketing";
import CreateAutomation from "views/auto-marketing/create-automation";
import Calendar from "views/calendar/viewProductions";
import Integrate from "views/integrate";
import { IoIosConstruct, IoIosMegaphone, IoMdHome } from "react-icons/io";
import MetaAddonInfo from "views/integrate/meta";
import PostScheduler from "views/calendar/createMedia";
import EditMedia from "views/calendar/editMedia";
import Ranking from "views/ranking/Ranking";
import Keywords from "views/ranking/Keywords";
import CreateRanking from "views/ranking/CreateRanking";
import StoryScheduler from "views/calendar/createStory";
import TabletOnboarding from "views/dashboards/tablet-onboarding";
import ImportUberOrders from "views/importUberOrders";
import GeneratePlanning from "views/calendar/generatePlanning";
import MediaList from "views/calendar/MediaList";
import GenerateDescription from "views/calendar/GenerateDescription";
import Drive from "views/drive/Drive";
import Attract from "views/tools/Attract";
import Retain from "views/tools/Retain";
import Reengage from "views/tools/Reengage";
import GenerateStories from "views/calendar/generateStories";

const routes = [
    // On menu
    {
        name: "Accueil",
        path: "/dashboard",
        layout: "",
        menu: false,
        icon: <IoMdHome className="text-inherit h-5 w-5" />,
        component: <Home />,
    },
    {
        name: "Attirer",
        path: "/attract",
        layout: "",
        icon: <MdCampaign className="text-inherit h-5 w-5" />,
        component: <Attract />,
        collapse: true,
        items: [
            {
                name: "Réseaux sociaux",
                layout: "",
                path: "calendar",
                //menu: true,
                //icon: <IoCalendarOutline className="text-inherit h-2 w-2" />,
                component: <Calendar />,
            },
            {
                name: "Référencement",
                path: "/ranking",
                layout: "",
                menu: true,
                //icon: <IoSpeedometer className="text-inherit h-5 w-5" />,
                component: <Ranking />,
            },
            // {
            //     name: "Marketing",
            //     path: "/marketing",
            //     layout: "",
            //     //icon: <IoIosMegaphone className="text-inherit h-3 w-3" />,
            //     component: <Marketing />,
            // },
        ],
    },
    {
        name: "Fidéliser",
        path: "/retain",
        layout: "",
        icon: <MdCached className="text-inherit h-5 w-5" />,
        component: <Retain />,
        collapse: true,
        items: [
            {
                name: "Tablette de fidélité",
                path: "/tablet-onboarding",
                layout: "",
                //icon: <MdHome className="text-inherit h-5 w-5" />,
                component: <TabletOnboarding />,
            },
            {
                name: "Mes clients",
                path: "/my-customers",
                layout: "",
                //icon: <MdOutlinePersonPin className="text-inherit h-5 w-5" />,
                component: <CRM />,
            },
        ],
    },
    {
        name: "Réengager",
        path: "/reengage",
        layout: "",
        icon: <MdAutoGraph className="text-inherit h-5 w-5" />,
        component: <Reengage />,
        collapse: true,
        items: [
            {
                name: "Campagnes",
                path: "/marketing",
                layout: "",
                //icon: <IoIosMegaphone className="text-inherit h-3 w-3" />,
                component: <Marketing />,
            },
            {
                name: "Ciblage client",
                path: "/customers-lists",
                layout: "",
                //icon: <IoIosMegaphone className="text-inherit h-3 w-3" />,
                component: <Marketing />,
            },
            {
                name: "Automatisations",
                path: "/auto-marketing",
                layout: "",
                //icon: <IoIosMegaphone className="text-inherit h-3 w-3" />,
                component: <Marketing />,
            },
        ],
    },
    // {
    //     name: "Activité boutique",
    //     path: "/shop",
    //     layout: "",
    //     icon: <IoMdPulse className="text-inherit h-5 w-5" />,
    //     component: <Shop />,
    // },
    {
        name: "Marketing",
        path: "/marketing",
        layout: "",
        menu: false,
        icon: <IoIosMegaphone className="text-inherit h-5 w-5" />,
        component: <Marketing />,
    },
    {
        name: "Calendrier",
        layout: "",
        path: "/calendar",
        menu: false,
        icon: <IoCalendarOutline className="text-inherit h-5 w-5" />,
        component: <Calendar />,
    },
    {
        name: "Outils",
        path: "/tools",
        layout: "",
        menu: false,
        icon: <IoIosConstruct className="text-inherit h-5 w-5" />,
        component: <Tools />,
    },
    {
        name: "Référencement",
        path: "/ranking",
        layout: "",
        menu: false,
        icon: <IoSpeedometer className="text-inherit h-5 w-5" />,
        component: <Ranking />,
    },
    {
        name: "Positionnement",
        path: "/ranking/create",
        layout: "",
        menu: false,
        icon: <IoSpeedometer className="text-inherit h-5 w-5" />,
        component: <CreateRanking />,
    },
    {
        name: "Clients",
        path: "/my-customers",
        layout: "",
        menu: false,
        icon: <MdOutlinePersonPin className="text-inherit h-5 w-5" />,
        component: <CRM />,
    },
    // Off menu
    {
        name: "Dashboard",
        path: "/create-media",
        layout: "",
        menu: false,
        icon: <MdHome className="text-inherit h-5 w-5" />,
        component: <PostScheduler />,
    },
    {
        name: "Dashboard",
        path: "/generate-planning",
        layout: "",
        menu: false,
        icon: <MdHome className="text-inherit h-5 w-5" />,
        component: <GeneratePlanning />,
    },
    {
        name: "Dashboard",
        path: "/generate-stories",
        layout: "",
        menu: false,
        icon: <MdHome className="text-inherit h-5 w-5" />,
        component: <GenerateStories />,
    },
    {
        name: "Dashboard",
        path: "/medias",
        layout: "",
        menu: false,
        icon: <MdHome className="text-inherit h-5 w-5" />,
        component: <MediaList />,
    },
    {
        name: "Drive",
        path: "/drive",
        layout: "",
        menu: false,
        icon: <MdHome className="text-inherit h-5 w-5" />,
        component: <Drive />,
    },
    {
        name: "Dashboard",
        path: "/generate-description",
        layout: "",
        menu: false,
        icon: <MdHome className="text-inherit h-5 w-5" />,
        component: <GenerateDescription />,
    },
    {
        name: "Dashboard",
        path: "/create-story",
        layout: "",
        menu: false,
        icon: <MdHome className="text-inherit h-5 w-5" />,
        component: <StoryScheduler />,
    },
    {
        name: "Positionnement",
        path: "/ranking/:id",
        layout: "",
        menu: false,
        icon: <IoSpeedometer className="text-inherit h-5 w-5" />,
        component: <Keywords />,
    },
    {
        name: "Dashboard",
        path: "/edit-media/:id",
        layout: "",
        menu: false,
        icon: <MdHome className="text-inherit h-5 w-5" />,
        component: <EditMedia />,
    },
    {
        name: "Dashboard",
        path: "/shop-settings",
        layout: "",
        menu: false,
        icon: <MdHome className="text-inherit h-5 w-5" />,
        component: <DashboardsDefault />,
    },
    {
        name: "Fidélité",
        path: "/tablet-onboarding",
        layout: "",
        menu: false,
        icon: <MdHome className="text-inherit h-5 w-5" />,
        component: <TabletOnboarding />,
    },
    {
        name: "Qr-Code",
        path: "/print",
        layout: "",
        icon: <MdOutlineShoppingBag className="text-inherit h-7 w-7" />,
        menu: false,
        component: <PrintQr />,
    },
    {
        name: "Roue cadeaux",
        path: "/wheel-manager",
        layout: "",
        icon: <MdOutlineShoppingBag className="text-inherit h-7 w-7" />,
        menu: false,
        component: <WheelManager />,
    },
    {
        name: "",
        path: "/in-connect",
        layout: "",
        menu: false,
        icon: <MdOutlinePersonPin className="text-inherit h-5 w-5" />,
        component: <InConnect />,
    },
    {
        name: "Page",
        path: "/page-manager",
        layout: "",
        icon: <MdOutlineShoppingBag className="text-inherit h-7 w-7" />,
        menu: false,
        component: <PageManager />,
    },
    {
        name: "Annonces",
        path: "/ads",
        layout: "",
        icon: <MdOutlineShoppingBag className="text-inherit h-7 w-7" />,
        menu: false,
        component: <Ads />,
    },
    {
        name: "Dashboard",
        path: "/performance",
        layout: "",
        icon: <MdOutlineShoppingBag className="text-inherit h-7 w-7" />,
        menu: false,
        component: <Performance />,
    },
    {
        name: "Scanner",
        path: "/scanner",
        layout: "",
        icon: <MdOutlineShoppingBag className="text-inherit h-7 w-7" />,
        menu: false,
        component: <Scan />,
    },
    {
        name: "UGC",
        path: "/ugc-creator",
        layout: "",
        icon: <MdOutlineShoppingBag className="text-inherit h-7 w-7" />,
        menu: false,
        component: <Ugc />,
    },
    {
        name: "Marketing",
        path: "/campaign/new",
        layout: "",
        menu: false,
        icon: <MdOutlineSms className="text-inherit h-5 w-5" />,
        component: <CreateCampaign />,
    },
    // --- Email Model ---
    {
        name: "Emails",
        path: "/email-model/",
        layout: "",
        menu: false,
        icon: <MdOutlineSms className="text-inherit h-5 w-5" />,
        component: <ListEmainModel />,
    },
    {
        name: "Marketing",
        path: "/email-model/:id",
        layout: "",
        menu: false,
        icon: <MdOutlineSms className="text-inherit h-5 w-5" />,
        component: <EditEmailModel />,
    },
    {
        name: "Marketing",
        path: "/sms-model/:id",
        layout: "",
        menu: false,
        icon: <MdOutlineSms className="text-inherit h-5 w-5" />,
        component: <EditSmsModel />,
    },
    {
        name: "Template",
        path: "/email-model/new",
        layout: "",
        menu: false,
        icon: <MdOutlineSms className="text-inherit h-5 w-5" />,
        component: <CreateEmailModel />,
    },
    {
        name: "Template",
        path: "/sms-model/new",
        layout: "",
        menu: false,
        icon: <MdOutlineSms className="text-inherit h-5 w-5" />,
        component: <CreateSMSModel />,
    },
    {
        name: "Template",
        path: "/template/new",
        layout: "",
        menu: false,
        icon: <MdOutlineSms className="text-inherit h-5 w-5" />,
        component: <CreateTemplate />,
    },
    {
        name: "Tokens",
        path: "/tokens",
        layout: "",
        menu: false,
        icon: <MdOutlineSms className="text-inherit h-5 w-5" />,
        component: <Tokens />,
    },
    // --- Customers Lists ---
    {
        name: "Ciblage client",
        path: "/customers-lists",
        layout: "",
        menu: false,
        icon: <MdOutlineSms className="text-inherit h-5 w-5" />,
        component: <CustomersLists />,
    },
    {
        name: "Automatisation",
        path: "/auto-marketing",
        layout: "",
        menu: false,
        icon: <MdOutlineSms className="text-inherit h-5 w-5" />,
        component: <AutoMarketing />,
    },
    {
        name: "Automatisation",
        path: "/auto-marketing/new",
        layout: "",
        menu: false,
        icon: <MdOutlineSms className="text-inherit h-5 w-5" />,
        component: <CreateAutomation />,
    },
    {
        name: "Ciblage client",
        path: "/customers-lists/:id",
        layout: "",
        menu: false,
        icon: <MdOutlineSms className="text-inherit h-5 w-5" />,
        component: <EditCustomersLists />,
    },
    {
        name: "Ciblage client",
        path: "/customers-lists/new",
        layout: "",
        menu: false,
        icon: <MdOutlineSms className="text-inherit h-5 w-5" />,
        component: <CreateCustomersLists />,
    },
    // --- Auth ---
    {
        name: "Default",
        menu: false,
        layout: "/auth",
        path: "/login",
        component: <SignInDefault />,
    },
    {
        name: "Default",
        menu: false,
        layout: "/auth",
        path: "/forgot-password",
        component: <ForgotPassword />,
    },
    {
        name: "Default",
        menu: false,
        layout: "/auth",
        path: "/reset-password/:id",
        component: <ResetPassword />,
    },
    {
        name: "Client",
        path: "/customer/:id",
        layout: "",
        menu: false,
        icon: <MdPallet className="text-inherit h-5 w-5" />,
        component: <EditCustomer />,
    },
    {
        name: "Edition client",
        path: "/edit-customer/:id",
        layout: "",
        menu: false,
        icon: <MdPallet className="text-inherit h-5 w-5" />,
        component: <EditInformation />,
    },
    {
        name: "Client",
        path: "/create-customer",
        layout: "",
        menu: false,
        component: <CreateCustomer />,
    },
    {
        name: "Client",
        path: "/import-uber-orders",
        layout: "",
        menu: false,
        component: <ImportUberOrders />,
    },
    {
        name: "Client",
        path: "/import-customer",
        layout: "",
        menu: false,
        component: <ImportCustomer />,
    },
    {
        name: "InsertLead",
        path: "/insert-lead",
        layout: "",
        menu: false,
        component: <InsertLead />,
    },
    {
        name: "Default",
        menu: false,
        layout: "/auth",
        path: "/register",
        component: <SignUpDefault />,
    },
    {
        name: "Default",
        menu: false,
        layout: "/auth",
        path: "/demo-contact",
        component: <LeadGen />,
    },
    {
        name: "Mon compte",
        layout: "",
        path: "/settings",
        menu: false,
        component: <AccountSettings />,
    },
    {
        name: "Checkout",
        layout: "",
        path: "/checkout",
        menu: false,
        component: <Subscribe />,
    },
    {
        name: "Extentions",
        layout: "",
        path: "/integrate",
        menu: false,
        component: <Integrate />,
    },
    {
        name: "Extentions",
        layout: "",
        path: "/integrate-code-gmb",
        menu: false,
        component: <Integrate />,
    },
    {
        name: "Extentions",
        layout: "",
        path: "/integrate/meta",
        menu: false,
        component: <MetaAddonInfo />,
    },
    {
        name: "Administration",
        path: "/dashboards",
        icon: <MdSupervisedUserCircle className="text-inherit h-5 w-5" />,
        collapse: true,
        menu: false,
        items: [
            {
                name: "Main Dashboard",
                layout: "/admin",
                path: "/dashboards/default",
                component: <DashboardsDefault />,
            },
            {
                name: "Car Interface",
                layout: "/admin",
                path: "/dashboards/car-interface",
                component: <DashboardsCarInterface />,
            },
            {
                name: "Smart Home",
                layout: "/admin",
                path: "/dashboards/smart-home",
                component: <DashboardsSmartHome />,
            },
            {
                name: "RTL",
                layout: "/rtl",
                path: "/dashboards/rtl",
                component: <DashboardsRTLDefault />,
            },
        ],
    },
];
export default routes;
