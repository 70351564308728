import ReactCrop from "react-image-crop";
import GoBack from "components/actions/GoBack";
import { createPlanning, uploadMedias } from "interfaces/content";
import { createRef, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import "react-image-crop/dist/ReactCrop.css";
import { MdArrowLeft, MdArrowRight } from "react-icons/md";

const aspects = [
    {
        label: "carré (1:1)",
        value: 1 / 1,
    },
    {
        label: "portrait (4:5)",
        value: 4 / 5,
    },
];

function GeneratePlanning() {
    const nav = useNavigate();
    const [loading, setLoading] = useState(false);
    const [platform, setPlatform] = useState("instagram");
    const [error, setError] = useState("");
    const canvasRef = useRef(null);

    const [medias, setMedias] = useState<any[]>([]);
    const [files, setFiles] = useState<any[]>([]);
    const [index, setIndex] = useState(0);
    const [crops, setCrops] = useState<any[]>([]);
    const [imagesAspect, setImagesAspect] = useState<any[]>([]);
    const [imageSizes, setImageSizes] = useState([]);
    const imageRefs = useRef<any[]>([]);

    const editAspect = (aspect: any) => {
        setImagesAspect((prev) => {
            const newAspects = [...prev];
            newAspects[index] = aspect;
            return newAspects;
        });
        if (aspect === 1) {
            setCrops((prev) => {
                const newCrops = [...prev];
                newCrops[index] = {
                    unit: "px",
                    x: 50,
                    y: 50,
                    width: 200,
                    height: 200,
                    aspect: 1,
                };
                return newCrops;
            });
        } else {
            setCrops((prev) => {
                const newCrops = [...prev];
                newCrops[index] = {
                    unit: "px",
                    x: 50,
                    y: 50,
                    width: 200,
                    height: 250,
                    aspect: 4 / 5,
                };
                return newCrops;
            });
        }
    };

    const handleMediaUpload = (e: any) => {
        const files = e.target.files;
        setFiles(files);

        let medias: any[] = [];
        if (files.length > 0) {
            setImagesAspect(new Array(files.length).fill(1));
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const media = URL.createObjectURL(file);
                medias.push(media);
            }
            console.log(medias);
            setMedias(medias);
        }
    };

    const getCroppedImage = useCallback(
        (imageSrc: any, crop: any, imageSize: any) => {
            console.log("getCroppedImage", imageSrc, crop);
            return new Promise((resolve, reject) => {
                const image = new Image();
                image.src = imageSrc;
                image.crossOrigin = "anonymous";

                image.onload = () => {
                    const canvas = document.createElement(`canvas`);
                    const { width, height } = imageSize;
                    const scaleX = image.width / width;
                    const scaleY = image.height / height;

                    canvas.width = crop.width * scaleX;
                    canvas.height = crop.height * scaleY;
                    const ctx = canvas.getContext("2d");

                    if (!ctx) {
                        reject(new Error("Could not get canvas context"));
                        return;
                    }

                    ctx.drawImage(
                        image,
                        crop.x * scaleX,
                        crop.y * scaleY,
                        crop.width * scaleX,
                        crop.height * scaleY,
                        0,
                        0,
                        crop.width * scaleX,
                        crop.height * scaleY,
                    );
                    const randomImageName = Math.random()
                        .toString(36)
                        .substring(7);

                    canvas.toBlob((blob) => {
                        if (blob) {
                            const croppedFile = new File(
                                [blob],
                                `cropped_${randomImageName}.jpeg`,
                                {
                                    type: "image/jpeg",
                                },
                            );
                            console.log("canvas resolved", canvas);
                            resolve(croppedFile);
                        } else {
                            console.log("canvas failed", canvas);
                            reject(new Error("Image cropping failed"));
                        }
                    }, "image/jpeg");
                };

                image.onerror = (err) => {
                    reject(new Error("Failed to load image"));
                };
            });
        },
        [],
    );

    const handlePlatformChange = (e: any) => {
        setPlatform(e.target.value);
    };

    const handlePostCreation = async () => {
        if (files.length === 0) {
            setError("Veuillez sélectionner un média.");
            return;
        }
        //setLoading(true);

        let croppedHaveUndefined = false;
        if (crops.length === 0 || crops.length !== medias.length) {
            setError("Veuillez recadrer tous les médias.");
            setLoading(false);
            return;
        }

        if (croppedHaveUndefined) {
            setError("Veuillez recadrer tous les médias.");
            setLoading(false);
            return;
        }

        setLoading(true);

        // Get the cropped image blob
        console.log("imageRefs", imageRefs);
        const croppedFiles: any = await Promise.all(
            medias.map(async (media, index) => {
                const croppedFile = await getCroppedImage(
                    media,
                    crops[index],
                    imageSizes[index],
                );
                return croppedFile;
            }),
        );

        // Create FormData with cropped image
        const formDataArray = new FormData();
        croppedFiles.forEach((file: any) => {
            formDataArray.append("files", file);
        });

        // Upload media
        const uploadedMedia = await uploadMedias(formDataArray);
        if (uploadedMedia) {
            const post = await createPlanning({
                platform: platform,
                images: uploadedMedia.data.keys,
                caption: "",
                mediaType: "image",
            });
            if (post) {
                setLoading(false);
                nav("/medias");
            }
        }
    };

    useEffect(() => {
        imageRefs.current = imageRefs.current.slice(0, medias.length);
        while (imageRefs.current.length < medias.length) {
            imageRefs.current.push(createRef());
        }
    }, [medias]);

    useEffect(() => {
        console.log("ImageSizes", imageSizes);
    }, [imageSizes]);

    useEffect(() => {
        console.log(imageRefs.current);
    }, [imageRefs]);

    useEffect(() => {
        console.log("Crops", crops);
    }, [crops]);

    useEffect(() => {
        // Initialise crop for the first image
        if (crops[index] === undefined) {
            setCrops((prev) => {
                const newCrops = [...prev];
                newCrops[index] = {
                    unit: "px",
                    x: 50,
                    y: 50,
                    width: 200,
                    height: 200,
                    aspect: 1,
                };
                return newCrops;
            });
        }

        // Get image sizes
        if (imageRefs.current[index] && imageRefs.current[index].current) {
            const currentImage = imageRefs.current[index].current;
            const { width, height } = currentImage.getBoundingClientRect();

            setImageSizes((prevSizes) => {
                const newSizes = [...prevSizes];
                newSizes[index] = { width, height };
                return newSizes;
            });
        }
    }, [index, medias]);

    return (
        <div>
            <GoBack />
            <div className="mt-2 grid grid-cols-2 md:grid-cols-2 gap-4">
                <div className="col-span-1 bg-white shadow-lg rounded-lg p-4 border">
                    <h1 className="text-base font-bold">
                        Créer une publication
                    </h1>
                    <div className="mt-2 mb-4">
                        <label className="block text-gray-700 text-sm font-medium mb-2">
                            Plateforme
                        </label>
                        <select
                            value={platform}
                            onChange={handlePlatformChange}
                            className="block w-full px-4 py-2 border border-gray-300 rounded-xl"
                        >
                            <option value="both">Facebook + Instagram</option>
                            <option value="instagram">Instagram</option>
                            <option value="facebook">Facebook</option>
                        </select>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-medium mb-2">
                            Télécharger un Média
                        </label>
                        <input
                            type="file"
                            accept="image/*"
                            multiple={true}
                            onChange={handleMediaUpload}
                            className="block w-full text-sm text-fidiz-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-primary/80 rounded-xl hover:file:bg-primary/80 hover:file:cursor-pointer"
                        />
                        <p className="text-xs text-red-500 mt-2">{error}</p>
                    </div>
                    <canvas ref={canvasRef} style={{ display: "none" }} />
                    {loading ? (
                        <div className="mt-4 text-center">
                            <p className="text-lg text-gray-500">
                                Publication en cours...
                            </p>
                        </div>
                    ) : (
                        <button
                            className="w-full p-5 linear rounded-xl bg-primary text-white font-bold py-3 text-xs transition duration-200 hover:opacity-70"
                            onClick={handlePostCreation}
                        >
                            Planifier le Post
                        </button>
                    )}
                </div>

                <div className="col-span-1 bg-white shadow-lg rounded-lg p-4 border">
                    <div className="flex items-center justify-between">
                        <MdArrowLeft
                            onClick={() =>
                                setIndex((prev) =>
                                    prev - 1 < 0 ? medias.length - 1 : prev - 1,
                                )
                            }
                            size={24}
                            className="cursor-pointer"
                        />
                        <h1 className="text-base font-bold mb-4">
                            Prévisualisation {index + 1}/{medias.length}
                        </h1>
                        <MdArrowRight
                            onClick={() =>
                                setIndex((prev) =>
                                    prev + 1 > medias.length - 1 ? 0 : prev + 1,
                                )
                            }
                            size={24}
                            className="cursor-pointer"
                        />
                    </div>
                    <div className="mx-auto border-2 border-gray-200 rounded-lg p-4">
                        <div className="mb-4">
                            {medias.length > 0 ? (
                                <div className="">
                                    <ReactCrop
                                        crop={crops[index]}
                                        onChange={(newCrop: any) =>
                                            setCrops((prev) => {
                                                const newCrops = [...prev];
                                                newCrops[index] = newCrop;
                                                return newCrops;
                                            })
                                        }
                                        aspect={imagesAspect[index]}
                                    >
                                        <img
                                            ref={imageRefs.current[index]}
                                            src={medias[index]}
                                            alt="Crop preview"
                                            onLoad={() => {
                                                setMedias((prev) => {
                                                    const newMedias = [...prev];
                                                    return newMedias;
                                                });
                                            }}
                                        />
                                    </ReactCrop>
                                </div>
                            ) : (
                                <div className="w-full h-64 bg-gray-200 rounded-lg flex items-center justify-center text-gray-500">
                                    Aucun média sélectionné
                                </div>
                            )}
                        </div>
                        {aspects.map((asp) => (
                            <button
                                key={asp.label}
                                onClick={() => editAspect(asp.value)}
                                className={`${
                                    imagesAspect[index] === asp.value
                                        ? "bg-primary text-white"
                                        : "bg-gray-200 text-gray-500"
                                } px-4 py-2 rounded-lg mr-2`}
                            >
                                {asp.label}
                            </button>
                        ))}
                        <div>
                            <p className="text-sm font-medium text-gray-500">
                                {platform === "facebook"
                                    ? "Post Facebook"
                                    : "Post Instagram"}
                            </p>
                            <p className="mt-2 text-base text-gray-800">
                                {"Votre texte apparaîtra ici"}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GeneratePlanning;
