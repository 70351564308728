import Pagination from "components/pagination/Pagination";
import spyImage from "../../assets/img/spy.png";
import calendarImage from "../../assets/img/calendar.png";
import rankingImage from "../../assets/img/ranking.png";
import socialsImage from "../../assets/img/socials.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getKpis } from "interfaces/customer";
import GmbDashboard from "views/performance/components/gmbDashboard";
import InstaDashboard from "views/performance/components/InstaDashboard";

const Attract = () => {
    const [page, setPage] = useState(1);
    const [kpis, setKpis] = useState<any>(null);
    const items: any = [];
    const nav = useNavigate();

    const initKpis = async () => {
        const res = await getKpis(1, 1);
        if (res) setKpis(res);
        console.log(res);
    };

    useEffect(() => {
        initKpis();
    }, []);

    const ToolCard = (props: {
        path: string;
        icon: any;
        title: string;
        description: string;
        available?: boolean;
    }) => {
        const { path, icon, title, description, available = true } = props;

        return (
            <div
                className="relative border rounded-xl p-6 cursor-pointer hover:border-2 hover:bg-gray-50"
                onClick={() => nav(path)}
            >
                {!available && (
                    <div className="absolute right-1 top-1">
                        <p className="m-4 p-2 text-xs font-bold bg-gray-200 rounded-lg">
                            PROCHAINEMENT
                        </p>
                    </div>
                )}
                <div className="absolute right-1 top-1"></div>
                <img src={icon} alt="Data" className="w-[40px]" />
                <div>
                    <h1 className="mt-4 font-bold">{title}</h1>
                    <p className="text-sm mt-1">{description}</p>
                </div>
            </div>
        );
    };

    return (
        <div className="">
            <GmbDashboard kpis={kpis} refresh={initKpis} />
            {/* Instagram */}
            <div className="mt-4">
                <InstaDashboard kpis={kpis} refresh={initKpis} />
            </div>
            <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-10">
                <div>
                    <h1 className="text-xl md:mt-0 font-bold">
                        Devenez visible
                    </h1>
                    <p className="mt-2 text-gray-600 text-sm">
                        Utilisez les réseaux sociaux et Google pour attirer de
                        nouveaux clients
                    </p>
                    <div className="mt-4 w-[100%] h-[1px] bg-gray-200 rounded-full"></div>
                    <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <ToolCard
                            path="/calendar"
                            icon={calendarImage}
                            title="Planification"
                            description="Planifiez plusieurs mois de contenu en quelques minutes grâce à notre IA."
                        />
                        <ToolCard
                            path="/ranking"
                            icon={rankingImage}
                            title="Référencement"
                            description="Suivez le positionnement de vos mots clés et améliorez votre référencement."
                        />
                    </div>
                </div>
                <div>
                    <h1 className="mt-2 text-xl md:mt-0 font-bold">
                        Veille concurrentielle
                    </h1>
                    <p className="mt-2 text-gray-600 text-sm">
                        Gardez un oeil sur vos concurrents pour mieux vous
                        adapter
                    </p>
                    <div className="mt-4 w-[100%] h-[1px] bg-gray-200 rounded-full"></div>
                    <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <ToolCard
                            path="/ads"
                            icon={spyImage}
                            title="Annonces"
                            description="Vous pouvez suivre les annonces de vos concurrents grâce à notre outils de veille publicitaire."
                        />
                        <ToolCard
                            path="/ugc-creator"
                            icon={socialsImage}
                            title="Création UGC"
                            description="Inspirez vous des créations de vos concurrents et découvrez des créateurs avec qui collaborer."
                        />
                    </div>
                </div>
                <Pagination
                    extra="mt-3"
                    text={false}
                    maxItems={items.length}
                    itemsPerPage={[8]}
                    page={page}
                    onChange={(page: number, limit: number) => setPage(page)}
                />
            </div>
        </div>
    );
};

export default Attract;
