import FullCalendar from "@fullcalendar/react";
import frLocale from "@fullcalendar/core/locales/fr";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { MdSchedule } from "react-icons/md";

function EventCalendar(props: { calendarData: any; initialDate: string }) {
    const { calendarData, initialDate } = props;
    const nav = useNavigate();

    const renderEventContent = (eventInfo: any) => {
        return (
            <div
                className="relative flex h-[100%] w-full text-white border justify-between rounded-xl cursor-pointer shadow-sm"
                data-date={eventInfo.event.startStr}
                onClick={() => {
                    if (
                        eventInfo.event.extendedProps.permalink.includes(
                            "edit-media",
                        )
                    ) {
                        nav(eventInfo.event.extendedProps.permalink);
                    } else {
                        window.open(
                            eventInfo.event.extendedProps.permalink,
                            "_blank",
                        );
                    }
                }}
            >
                <div>
                    <div className="p-1 flex gap-1">
                        {eventInfo.event.extendedProps.origin ===
                            "instagram" && (
                            <FaInstagram className="flex my-auto text-[#515151e9]" />
                        )}
                        {eventInfo.event.extendedProps.origin ===
                            "facebook" && (
                            <FaFacebook className="flex my-auto text-[#515151e9]" />
                        )}
                        {eventInfo.event.extendedProps.origin ===
                            "schedule" && (
                            <MdSchedule className="flex my-auto text-[#515151e9]" />
                        )}
                        {eventInfo.event.extendedProps.isStory ? (
                            <p className="text-[#515151e9]">Story</p>
                        ) : (
                            <p className="text-[#515151e9]">Post</p>
                        )}
                    </div>
                    <div className="px-1">
                        {eventInfo.event.extendedProps.status ===
                        "published" ? (
                            <div className="flex gap-2">
                                <div className="flex my-auto bg-green-500 h-2 w-2 rounded-full"></div>
                                <p className="flex text-[#515151e9] text-xs">
                                    Publié
                                </p>
                            </div>
                        ) : (
                            <div className="flex gap-2">
                                <div className="flex my-auto bg-[#f5a623] h-2 w-2 rounded-full"></div>
                                <p className="flex text-[#515151e9] text-xs">
                                    Planifié
                                </p>
                            </div>
                        )}
                    </div>
                    <p className="ml-2 mt-2 text-[#515151e9] text-xs">
                        {new Date(eventInfo.event.start).getHours()}H
                    </p>
                </div>
                <img
                    src={eventInfo.event.extendedProps.imageUrl}
                    alt="post"
                    className="w-[70px] rounded-xl max-h-[80px] object-cover"
                />
            </div>
        );
    };

    return (
        <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
            locale={frLocale}
            dayHeaderFormat={{
                weekday: "short",
            }}
            initialView="dayGridMonth"
            events={calendarData}
            dayHeaderContent={(arg) => {
                return <span className="text-[#515151e9]">{arg.text}</span>;
            }}
            headerToolbar={{
                left: "prev,next", //dayGridMonth,timeGridWeek
                center: "title", //title
                right: "",
            }}
            // slotLabelContent={(arg) => {
            //     return <span className=""></span>;
            // }}
            eventDragStop={(arg) => {
                const { event, jsEvent } = arg; // The dragged event
                const previousDate = event.startStr;
                const target = jsEvent.target as HTMLElement;
                const newDate = target.dataset.date;
                console.log(previousDate, newDate);
                //Edit contant according to new date
                //console.log(arg);
                //console.log(`Event dragged from ${arg.event.start} to ?`);
                // Get event id
                //console.log(arg.event);
            }}
            eventMinHeight={400}
            initialDate={initialDate}
            allDaySlot={true}
            eventContent={renderEventContent}
            eventBorderColor="black"
            editable={true}
        />
    );
}

export default EventCalendar;
