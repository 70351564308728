import TalkImg from "assets/img/talk-logo.svg";
import shootingImg1 from "assets/img/shooting-1.jpg";
import shootingImg2 from "assets/img/shooting-2.jpg";
import shootingImg3 from "assets/img/shooting-3.jpg";
import { useState } from "react";

const faqs = [
    {
        question: "Agence de comm ou logiciel ?",
        answer: "Le parfait mélange d'une agence de communication allié à la puissance de la technologie et de l'analyse de données. Nous aimons nous qualifié d'agence de communication technologique.",
    },
    {
        question: "Je travaille seul, avec une agence ou un community manager",
        answer: "C'est super et tout à fait compatible avec notre solution !Nous avons développé une plateforme souple et personnalisable afin de pouvoir travailler aussi bien avec des propriétaires de restaurants directement mais aussi avec des agences ou CM. Notre solution est modulable et peut sans problème être utilisé par des agences afin de piloter, améliorer les stratégies de présence management, de planification de posts, réponses aux avis, etc, de leurs clients.",
    },
    {
        question: "Quels sont les différences de vos offres ?",
        answer: "Nous fonctionnons sous forme de module afin de laisser la possibilité à nos clients de pleinement utiliser notre logiciel selon la stratégie et les besoins de chacun. Vous pouvez aussi complètement nous laisser la gestion de votre marketing et communication digital ou simplement utiliser une ou plusieurs de nos fonctionnalités. C'est à la carte et c'est tellement plus simple pour vous !",
    },
];

const TestimonialCard = () => {
    return (
        <>
            <div className="mt-10 bg-white shadow-2xl rounded-lg p-6 max-w-sm mx-auto border-t">
                <div className="flex items-center mb-4">
                    {/* <img
                    src="https://via.placeholder.com/50"
                    alt="User"
                    className="w-12 h-12 rounded-full"
                /> */}
                    <div className="ml-4">
                        <h3 className="font-semibold text-gray-900">
                            Amir. A (Niels Garden) - Marseille
                        </h3>
                        <div className="flex">
                            {[...Array(5)].map((_, i) => (
                                <svg
                                    key={i}
                                    className="w-5 h-5 text-yellow-400"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.945a1 1 0 00.95.69h4.15c.97 0 1.372 1.24.588 1.81l-3.36 2.445a1 1 0 00-.364 1.118l1.287 3.945c.3.921-.755 1.688-1.54 1.118l-3.36-2.445a1 1 0 00-1.175 0l-3.36 2.445c-.785.57-1.84-.197-1.54-1.118l1.287-3.945a1 1 0 00-.364-1.118L2.264 9.373c-.784-.57-.382-1.81.588-1.81h4.15a1 1 0 00.95-.69l1.286-3.945z" />
                                </svg>
                            ))}
                        </div>
                    </div>
                </div>
                <p className="text-sm text-gray-600">
                    Super service, l'équipe est au top et je vois le logiciel
                    évoluer sous mes yeux. Quel plaisir !
                </p>
            </div>
        </>
    );
};

const FAQSection = () => {
    const [open, setOpen] = useState(null);

    const toggleFAQ = (index: number) => {
        setOpen(open === index ? null : index);
    };

    return (
        <div className="px-10 w-[90%] mx-auto py-10">
            <h2 className="text-lg font-bold text-center mb-7">
                Des questions ? Nous avons des réponses.
            </h2>
            <div className="space-y-4">
                {faqs.map((faq: any, index: number) => (
                    <div key={index} className="border-b">
                        <button
                            onClick={() => toggleFAQ(index)}
                            className="flex justify-between items-center w-full py-4 text-left focus:outline-none"
                        >
                            <span className="text-lg font-medium text-gray-900">
                                {faq.question}
                            </span>
                            <svg
                                className={`w-5 h-5 transform transition-transform ${
                                    open === index ? "rotate-180" : ""
                                }`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 9l-7 7-7-7"
                                ></path>
                            </svg>
                        </button>
                        {open === index && (
                            <div className="py-2">
                                <p className="text-gray-600">{faq.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

function AskADemo() {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 min-h-screen">
            <div className="bg-gray-50 rounded-sm h-full">
                <img
                    className={`pl-10 mt-2 w-[150px] rounded-xl`}
                    src={TalkImg}
                    alt="Toola logo"
                />
                <div className="mt-8 flex flex-col items-center justify-center mx-auto">
                    <h1 className="text-4xl font-bold text-[#131316] mb-2 text-center">
                        Soyez trouvé, soyez choisi et devenez
                    </h1>
                    <h2 className="text-4xl font-bold text-[#5167F6] underline">
                        incontournable.
                    </h2>
                </div>
                <div className="flex lg:hidden pt-[10%] pb-5 px-auto w-full max-w-full flex-col md:pl-4 lg:pl-0">
                    <div className="mx-auto">
                        <h3 className="text-2xl font-bold text-[#131316] mb-2 text-center">
                            Une petite démo ?
                        </h3>
                        <button
                            className="mt-4 group relative overflow-hidden w-full rounded-full text-xl py-3 font-bold text-white bg-primary"
                            onClick={() =>
                                (window.location.href =
                                    "https://app.lemcal.com/@fidiz/demo")
                            }
                        >
                            <span className="absolute left-0 top-0 mb-0 flex h-full w-0 translate-x-0 transform bg-blue-500 opacity-90 transition-all duration-300 ease-out group-hover:w-full"></span>
                            <span className="relative group-hover:text-white">
                                Planifier un appel
                            </span>
                        </button>
                    </div>
                </div>
                <div className="sm:mt-8 flex flex-col lg:flex-row items-center justify-around p-10 bg-gray-50 rounded-xl">
                    {/* Left Section */}
                    <div className="relative flex flex-col items-center">
                        <img
                            src={shootingImg1}
                            alt="Woman in green sweater"
                            className="w-72 h-96 bg-gray-80 rounded-[50px] object-cover"
                        />
                        <span className="absolute font-bold z-50 top-2 right-[-30px] bg-[#5167F6] text-white px-4 py-2 rounded-full">
                            Technologie
                        </span>
                        <div className="absolute bottom-[-20px] left-10 bg-white p-4 rounded-xl shadow-lg">
                            <div className="flex items-center space-x-2">
                                <span className="relative flex h-3 w-3">
                                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                    <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                                </span>
                                <span className="text-sm font-medium text-gray-900">
                                    Fidiz en direct
                                </span>
                            </div>
                            <p className="text-sm text-gray-600">
                                Shooting de la semaine
                            </p>
                            <p className="text-sm font-bold">
                                L'astrolabe, Paris
                            </p>
                        </div>
                    </div>

                    {/* Middle Section */}
                    <div className="relative flex flex-col items-center mt-10 lg:mt-0">
                        <img
                            src={shootingImg2}
                            alt="Woman in blue sweater"
                            className="z-10 w-72 h-96 bg-gray-90 rounded-[50px] object-cover"
                        />
                        <span className="absolute font-bold z-50 top-[70px] right-[-40px] bg-blue-400 text-white px-4 py-2 rounded-full">
                            + Communication
                        </span>
                    </div>

                    {/* Right Section */}
                    <div className="relative flex flex-col items-center mt-10 lg:mt-0">
                        <img
                            src={shootingImg3}
                            alt="Man in green sweater"
                            className="w-72 h-96 bg-gray-100 rounded-[50px] object-cover"
                        />
                        <div className="absolute top-[-20px] bg-white p-3 rounded-full shadow-lg">
                            <span className="text-lg font-bold text-[#5167F6]">
                                = croissance 🚀
                            </span>
                        </div>
                    </div>
                </div>
                {/* <div className="flex flex-col items-center justify-center">
                    <img
                        className="mt-5 md:mt-10 mx-auto w-[200%]"
                        src={campaignImg}
                        alt="Campaign"
                    />
                </div> */}
            </div>
            <div className="pt-[10%] pb-10 flex px-auto w-full max-w-full flex-col md:pl-4 lg:pl-0">
                <div className="mx-auto">
                    <h3 className="text-4xl font-bold text-[#131316] mb-2 text-center">
                        Une petite démo ?
                    </h3>
                    <button
                        className="mt-4 group relative overflow-hidden w-full rounded-full text-xl py-3 font-bold text-white bg-primary"
                        onClick={() =>
                            (window.location.href =
                                "https://app.lemcal.com/@fidiz/demo")
                        }
                    >
                        <span className="absolute left-0 top-0 mb-0 flex h-full w-0 translate-x-0 transform bg-blue-500 opacity-90 transition-all duration-300 ease-out group-hover:w-full"></span>
                        <span className="relative group-hover:text-white">
                            Planifier un appel
                        </span>
                    </button>
                </div>
                <TestimonialCard />
                <FAQSection />
                {/* <img
                    className={`flex mx-auto mt-3 border border-[#1f2937] w-[60%] rounded-xl`}
                    src={featuresImg}
                    alt="Features"
                /> */}
            </div>
        </div>
    );
}

export default AskADemo;
