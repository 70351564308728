import GoBack from "components/actions/GoBack";
import { getAllContent } from "interfaces/content";
import { useEffect, useState } from "react";
import { FaInstagram } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { dateToFrench } from "utils/date";

function MediaList() {
    const [content, setContent] = useState<any>([]);
    const nav = useNavigate();

    const initContent = async () => {
        const res = await getAllContent();
        if (res.error) return;
        setContent(res);
    };

    useEffect(() => {
        initContent();
    }, []);

    const difBetweenDates = (date1: Date, date2: Date) => {
        const diff = date1.getTime() - date2.getTime();

        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        return days;
    };

    return (
        <div>
            <GoBack />
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                <h1 className="text-2xl font-bold">
                    Vos {content?.length} prochaines publications
                </h1>
                <div className="flex justify-end">
                    <button
                        onClick={() => nav("/generate-description")}
                        className="bg-primary text-white px-4 py-2 rounded-lg"
                    >
                        Générer les descriptions
                    </button>
                </div>
                {/* Display Skelleton */}
                {content.length === 0 &&
                    [1, 2, 3, 4, 5, 6].map((i) => (
                        <div className="relative col-span-1 bg-white shadow-lg rounded-lg p-4 border animate-pulse">
                            <div className="flex gap-2 my-auto">
                                <FaInstagram
                                    size={20}
                                    className="flex my-auto text-[#515151e9]"
                                />
                                <div className="w-[200px] h-[15px] rounded-lg bg-gray-500"></div>
                            </div>
                            <div className="absolute top-4 right-3 text-sm">
                                <div className="w-[140px] h-[10px] rounded-lg bg-gray-100"></div>
                            </div>
                            <div className="flex gap-2">
                                <div className="mt-2 w-[20%] h-[100px] bg-gray-100 rounded-lg border-2 border-black-200"></div>
                                <div className="mt-1 ml-5">
                                    <div className="mt-3 w-[140px] h-[10px] rounded-lg bg-gray-100"></div>
                                    <div className="mt-3 w-[200px] h-[10px] rounded-lg bg-gray-100"></div>
                                    <div className="mt-3 w-[200px] h-[10px] rounded-lg bg-gray-100"></div>
                                    <div className="mt-3 w-[160px] h-[10px] rounded-lg bg-gray-100"></div>
                                </div>
                            </div>
                        </div>
                    ))}
                {/* Display content */}
                {content?.map((c: any) => (
                    <div
                        className="relative col-span-1 bg-white shadow-lg rounded-lg p-4 border hover:cursor-pointer hover:shadow-xl hover:bg-gray-100"
                        onClick={() => {
                            window.open(c.permalink, "_blank");
                        }}
                    >
                        <div className="flex gap-2 my-auto">
                            <FaInstagram
                                size={20}
                                className="flex my-auto text-[#515151e9]"
                            />
                            <h1 className="my-auto text-base font-bold">
                                {difBetweenDates(
                                    new Date(c.start),
                                    new Date(),
                                ) > 0
                                    ? `Publication dans ${difBetweenDates(
                                          new Date(c.start),
                                          new Date(),
                                      )} jours`
                                    : "Publication imminente"}
                            </h1>
                            <p className="absolute top-4 right-3 text-xs md:text-sm">
                                {dateToFrench(c.start)} à{" "}
                                {c.start.split("T")[1].split(":")[0]}H
                            </p>
                        </div>
                        <div className="flex gap-2">
                            <img
                                src={c.imageUrl}
                                alt={c.title}
                                className="mt-2 w-[20%] rounded-lg border-2 border-black-200"
                            />
                            <div className="mt-1 ml-5">
                                <p className="mt-2 text-sm">
                                    ✅ Planification du post
                                </p>
                                <p className="mt-2 text-sm">
                                    {c.caption === "" ? "⏳" : "✅"} Génération
                                    de la description
                                </p>
                                <p className="mt-2 text-sm">
                                    {c.caption === "" ? "⏳" : "✅"}{" "}
                                    Vérification de la description
                                </p>
                                <p className="mt-2 text-sm">
                                    {c.caption?.includes("#") ? "✅" : "⏳"}{" "}
                                    Mots-clés et hashtags
                                </p>
                            </div>
                        </div>
                        <p className="text-sm">{c.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MediaList;
