import GoBack from "components/actions/GoBack";
import { getDriveContent } from "interfaces/content";
import { useEffect, useState } from "react";
import { FaInstagram } from "react-icons/fa";
import { MdInsertPhoto } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { dateToFrench, dateToFrenchShort } from "utils/date";

function Drive() {
    const [content, setContent] = useState<any>([]);
    const nav = useNavigate();

    const initContent = async () => {
        const res = await getDriveContent();
        if (res.error) return;
        setContent(res);
    };

    useEffect(() => {
        initContent();
    }, []);

    const difBetweenDates = (date1: Date, date2: Date) => {
        const diff = date1.getTime() - date2.getTime();

        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        return days;
    };

    return (
        <div>
            <GoBack />
            <div className="mt-3 gap-4">
                <div className="flex mb-3 justify-between">
                    <h1 className="text-2xl font-bold">Mon Drive Fidiz</h1>
                    <button
                        onClick={() => nav("/generate-description")}
                        className="bg-primary text-white px-2 py-2 text-sm rounded-lg"
                    >
                        Upload des fichiers
                    </button>
                </div>
                {/* Display Skelleton */}
                <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
                    {content.length === 0 &&
                        [1, 2, 3, 4, 5, 6].map((i) => (
                            <div className="relative col-span-1 bg-white shadow-lg rounded-lg p-4 border animate-pulse">
                                <div className="flex gap-2 my-auto">
                                    <FaInstagram
                                        size={20}
                                        className="flex my-auto text-[#515151e9]"
                                    />
                                    <div className="w-[200px] h-[15px] rounded-lg bg-gray-500"></div>
                                </div>
                                <div className="absolute top-4 right-3 text-sm">
                                    <div className="w-[140px] h-[10px] rounded-lg bg-gray-100"></div>
                                </div>
                                <div className="flex gap-2">
                                    <div className="mt-2 w-[20%] h-[100px] bg-gray-100 rounded-lg border-2 border-black-200"></div>
                                    <div className="mt-1 ml-5">
                                        <div className="mt-3 w-[140px] h-[10px] rounded-lg bg-gray-100"></div>
                                        <div className="mt-3 w-[200px] h-[10px] rounded-lg bg-gray-100"></div>
                                        <div className="mt-3 w-[200px] h-[10px] rounded-lg bg-gray-100"></div>
                                        <div className="mt-3 w-[160px] h-[10px] rounded-lg bg-gray-100"></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    {/* Display content */}
                    {content?.map((c: any) => (
                        <div
                            className="relative max-h-[300px] col-span-1 shadow-lg rounded-lg hover:cursor-pointer hover:shadow-xl border hover:bg-gray-100"
                            onClick={() => {
                                window.open(c.permalink, "_blank");
                            }}
                        >
                            <div className="mt-2 ml-3 flex gap-2 my-auto">
                                <MdInsertPhoto
                                    size={15}
                                    className="flex my-auto"
                                />
                                <h1 className="my-auto text-sm">Photo</h1>
                            </div>
                            {Math.floor(Math.random() * (2 - 1 + 1) + 1) ===
                            2 ? (
                                <div className="absolute top-3 right-3 bg-primary px-2 rounded-lg text-sm">
                                    <p className="text-white text-xs">
                                        Planifié
                                    </p>
                                </div>
                            ) : (
                                <div className="absolute top-3 right-3 bg-green-600 px-2 rounded-lg text-sm">
                                    <p className="text-white text-xs">
                                        Disponible
                                    </p>
                                </div>
                            )}
                            <div className="flex">
                                <img
                                    src={c.imageUrl}
                                    alt={c.title}
                                    className="flex mx-auto mb-1 mt-2 max-h-[200px] rounded-lg border-2 border-black-200"
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Drive;
